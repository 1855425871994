import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/src/theme/appclassic';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppClassic/Navbar';
import Banner from '../containers/Agency/ContactUsBanner/index-success';
import Footer from '../containers/Agency/Footer';
import GlobalStyle, { AppWrapper, ContentWrapper } from '../containers/AppClassic/appClassic.style';
import SEO from '../components/seo';
import Newsletter from '../containers/Agency/Newsletter';

export default function() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="Archer Health | Empowering Employees and Employers" />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
            <Newsletter />
            <Footer />
          </ContentWrapper>
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
